import * as React from 'react';
import Layout from './components/Layout';
import { Trans, useTranslation } from 'react-i18next';
import CustomerOrdersGrid from './components/Orders/CustomerOrdersGrid';
import SupplierOrdersGrid from './components/Orders/SupplierOrdersGrid';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import SupplierOrderDetail from './components/Orders/SupplierOrderItems';
import { Fade } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { NotificationMessage } from './store/interfaces';
import { CookiesUtil } from './Module/CookiesUtil';
import ViewInvoice from './components/Invoice/ViewInvoice';
import CustomerOrderDetail from './components/Orders/CustomerOrderDetail';
import enMessages from "./locales/en.json";
import frMessages from "./locales/fr.json";
import { loadMessages } from '@progress/kendo-react-intl';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import SupportRequest from './components/SupportRequest';
import { get } from 'http';
import * as Tabs from './store/Tabs/Tabs';
import { ApplicationState } from './store';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import CustomerOrderItems from './components/Orders/CustomerOrderItems';
import { CUSTOMER_ORDER_GRID_TAB_ID_PREFIX, SUPPLIER_ORDER_GRID_TAB_ID_PREFIX } from './store/constants';

const App = () => {
    const { t, i18n } = useTranslation();
    const [key, setKey] = React.useState(Math.random());
    const [notificationMessages, setNotificationMessages] = React.useState<NotificationMessage[]>(CookiesUtil.getNotificationMessgaes());
    const dispatch = useDispatch();
    const tabs = useSelector((state: ApplicationState) => state.Tabs?.tabs);
    const selectedTab = useSelector((state: ApplicationState) => state.Tabs?.selected);

    if (i18n.language == "fr") {
        loadMessages(frMessages, "fr");
    } else {
        loadMessages(enMessages, "en");
    }

    const initialTabs = [{
        title: i18n.t("customerOrders"),
        id: CUSTOMER_ORDER_GRID_TAB_ID_PREFIX,
        content: {
            name: CustomerOrdersGrid,
            props: {
                orderHeaderId: 0,
                onSelectOrdersCallback:"undefined",
                selectionMode: "multiple",
                displayAction: true,
                fetchEditableOrdersOnly: false
            }
        },
        visible: true,
        closable: false
    }, {
        title: i18n.t("supplierOrders"),
        id: SUPPLIER_ORDER_GRID_TAB_ID_PREFIX,
        content: {
            name: SupplierOrdersGrid,
            props: {
            }
        },
        visible: true,
        closable: false
    }];

    React.useEffect(() => {
        dispatch(Tabs.actionCreators.onAddNewTabs(initialTabs));
    }, []);


    const Title = (props: any) => {
        return (
            <React.Fragment>
                <div dangerouslySetInnerHTML={{__html: props.content}} />
                {props.closable &&
                    <><span className="k-spacer" />
                        <span
                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button"
                            onClick={() => props.onTabRemove(props.content)}
                        >
                            <span className="k-icon k-i-x" />
                        </span>
                    </>
                }
            </React.Fragment>
        );
    };

    const removeTab = (tabId: string) => {
        dispatch(Tabs.actionCreators.onRemoveTab(tabId));
    };

    const handleSelectTab = (e: any) => {
        dispatch(Tabs.actionCreators.onselectTab(e))
    }

    React.useEffect(() => {
        CookiesUtil.removeReadNotifications();
        setInterval(function () { setNotificationMessages(CookiesUtil.getNotificationMessgaes()) }, 1000);
        setInterval(function () { CookiesUtil.cleanUpNotificationMessages() }, 1000);

    }, []);

    return (
        <>
            <Layout>
                <Fade>
                    <NotificationGroup style={{
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}>

                        {notificationMessages.map((n) => {
                            CookiesUtil.setNotificationMessageAsRead(n);
                            return <Notification
                                style={{
                                    bottom: 0,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                }}
                                type={{
                                    style: n.type,
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => CookiesUtil.removeNotificationMessage(n)}

                            >
                                <span>{decodeURIComponent(n.message)}</span>
                            </Notification>
                        })}


                    </NotificationGroup>
                </Fade>

                <Routes>
                    <Route path="/:lang"
                        element={<TabStrip keepTabsMounted={true} selected={selectedTab} onSelect={handleSelectTab}>
                            {tabs?.map((item, index) => {
                                return item.visible && <TabStripTab title={<Title content={item.title} onTabRemove={(e : any) => {removeTab(item.id)}} closable={item.closable} />} key={index}>
                                    <div>
                                        <item.content.name {...item.content.props} />
                                    </div>
                                </TabStripTab>;
                            })}
                        </TabStrip>}>
                    </Route>
                    <Route path="/"
                        element={<TabStrip keepTabsMounted={true} selected={selectedTab} onSelect={handleSelectTab}>
                            {tabs?.map((item, index) => {
                                return item.visible && <TabStripTab title={<Title content={item.title} onTabRemove={(e : any) => {removeTab(item.id)}} closable={item.closable} />} key={index}>
                                    <div>
                                        <item.content.name {...item.content.props} />
                                    </div>
                                </TabStripTab>;
                            })}
                        </TabStrip>}>
                    </Route>
                    <Route
                        path="support-request"
                        element={<SupportRequest />}
                    />
                    <Route
                        path="/:lang/support-request"
                        element={<SupportRequest />}
                    />
                </Routes>

            </Layout>
        </>
    )
};

export default App;