import * as React from 'react';
import { getSelectedState, Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridExpandChangeEvent, GridHeaderCellProps, GridHeaderSelectionChangeEvent, GridItemChangeEvent, GridRowProps, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { getter, State } from '@progress/kendo-data-query';
import { SelectionState } from '../../store/interfaces';
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/fr/numbers.json";
import currencies from "cldr-numbers-full/main/fr/currencies.json";
import caGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import { CookiesUtil } from '../../Module/CookiesUtil';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimeUtil } from '../../Module/DateTimeUtil';
import { Button } from "@progress/kendo-react-buttons";
import * as CustomerOrdersStore from '../../store/Orders/CustomerOrders';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { loadingPanel } from '../loadingPanel';
import { DateTimeCell, HeaderTooltipCell, MoneyCell } from '../../Module/renderers';
import { ConfirmDialog } from '../../Module/Dialog/ConfirmDialog';
import InvoiceUpload from '../Invoice/InvoiceUpload';
import { MoneyFormat } from '../../Module/CurrencyUtil';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Popover, PopoverActionsBar, Tooltip } from "@progress/kendo-react-tooltip";
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { getSelectedIds, updateGridFooterPager } from '../../Module/Utils';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { TextFilterCell } from '../../Module/filterCell/TextFilterCell';
import { DateFilterCell } from '../../Module/filterCell/DateFilterCell';
import { AutoCompleteFilterCell } from '../../Module/filterCell/AutoCompleteFilterCell';
import { TableSelectableMode } from '@progress/kendo-react-data-tools';
import * as TabsStore from '../../store/Tabs/Tabs';
import CustomerOrderDetail from './CustomerOrderDetail';
import SupplierOrderDetail from './SupplierOrderItems';
import { CUSTOMER_ORDER_DETAILS_TAB_ID_PREFIX, SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX } from '../../store/constants';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

interface MyComponentOwnProps {
    orderHeaderId: number,
    onSelectOrdersCallback: ((orderIds: number) => void) | undefined,
    selectionMode: TableSelectableMode | undefined,
    displayAction: boolean | undefined,
    fetchEditableOrdersOnly: boolean
}

type CustomerOrdersProps =
    CustomerOrdersStore.CustomerOrdersState // ... state we've requested from the Redux store
    & typeof CustomerOrdersStore.actionCreators // ... plus action creators we've requested
    & TabsStore.TabsState
    & typeof TabsStore.actionCreators
    & RouteComponentProps
    & MyComponentOwnProps

const CustomerOrdersGrid = (props: CustomerOrdersProps) => {
    const { t, i18n } = useTranslation();

    const [dataState, setDataState] = React.useState<State>({ take: 10, skip: 0 });
    const [accessToken, setAccessToken] = React.useState<string>(CookiesUtil.getCookieValue("accessToken"));
    const [visible, setVisible] = React.useState<boolean>(false);

    const _export = React.useRef<ExcelExport | null>(null);
    const [exportCustomerOrders, setExportCustomerOrders] = React.useState<boolean>();
    const [selectedorderStatusId, setSelectedorderStatusId] = React.useState<number | null>(null);
    const [selectedState, setSelectedState] = React.useState<SelectionState>({});

    const [uploadInvoiceComponentCallNumber, setUploadInvoiceComponentCallNumber] = React.useState<number>(0);
    const [viewInvoiceWindowCallNumber, setViewInvoiceWindowCallNumber] = React.useState<number>(0);


    const [selectedOrderId, setSelectedOrderId] = React.useState<number | null>(null);

    const DATA_ITEM_KEY: string = "estaff_order_number";
    const SELECTED_FIELD: string = "selected";
    const idGetter = getter(DATA_ITEM_KEY);

    const toggleDialog = () => {
        setVisible(!visible);
    };

    const orderStatusCell = (cellProps: GridCellProps) => {
        const field = cellProps.field || "";
        return (
            <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}
                onContextMenu={(e) => {
                    e.preventDefault()
                    props.OnContextMenuOpen(e, cellProps)
                }
                }>
                {cellProps.dataItem[field]}
            </td>
        );
    };

    const ViewInvoiceCell = (cellProps: GridCellProps) => {
        const field = cellProps.field || "";
        return (
            <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>
                <a href="#"
                    onClick={(e) => {
                        /* props.onAddNewTabs([{ 
                             title: i18n.t("invoice"), 
                             content: <InvoiceUpload orderId={cellProps.dataItem["estaff_order_number"] as any} orderStatusId={}/>,
                             visible: true, closable: true }]); 
                         //props.props.displayCustomerOrderInvoiceTabs(cellProps.dataItem["estaff_order_number"]) */
                    }}>
                    {cellProps.dataItem["invoice_number_list"]}
                </a>
            </td>
        );
    };

    const actionCell = (cellProps: GridCellProps) => {
        return (
            <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>

                <a
                    href="#"
                    onClick={(e) => {
                        props.onAddNewTabs([{
                            title: i18n.t('CustomerOrderShort') + " # " + cellProps.dataItem["estaff_order_number"]  + " " + cellProps.dataItem["customer_name"] ,
                            id: CUSTOMER_ORDER_DETAILS_TAB_ID_PREFIX + cellProps.dataItem["estaff_order_number"],
                            content: {
                                name: CustomerOrderDetail,
                                props: { orderHeaderId: cellProps.dataItem["estaff_order_number"] }
                            },
                            visible: true,
                            closable: true
                        }]);
                    }}
                    className="fa fa-external-link-square-alt fa-fw action-link"
                ></a>
                <>&nbsp;&nbsp;&nbsp;</>
                <a
                    href="#"
                    onClick={(e) => { props.OpenConfirmCancelOrderWindow([cellProps.dataItem["estaff_order_number"]]) }}
                    className="fa fa-trash action-link"
                ></a>
                <>&nbsp;&nbsp;&nbsp;</>
                {props.connectwiseParams != null && props.connectwiseParams.param_value == "1" &&
                    <>
                        {
                            cellProps.dataItem["external_status"] == 0 &&
                            <a
                                href="#"
                                onClick={(e) => { props.onPromptCreateOrderIntoCW(cellProps.dataItem["estaff_order_number"]) }}
                                className="fa fa-plus-circle action-link"
                            >
                            </a>
                        }
                        {cellProps.dataItem["external_status"] == 1 && <><i className="fa fa-check" style={{ color: "green" }}></i></>}
                        {cellProps.dataItem["external_status"] == -1 && <><i className="fa fa-bug" style={{ color: "red" }}></i></>}
                    </>
                }


            </td>
        );
    };


    const estaffLinkCell = (cellProps: GridCellProps) => {
        const field = cellProps.field || "";
        return (
            <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>
                <a
                    href="#"
                    onClick={(e) => {
                        props.onAddNewTabs([{
                            title: i18n.t('CustomerOrderShort') + " # " + cellProps.dataItem["estaff_order_number"]  + " " + cellProps.dataItem["customer_name"] ,
                            id: CUSTOMER_ORDER_DETAILS_TAB_ID_PREFIX + cellProps.dataItem["estaff_order_number"],
                            content: {
                                name: CustomerOrderDetail,
                                props: { orderHeaderId: cellProps.dataItem["estaff_order_number"] }
                            },
                            visible: true,
                            closable: true
                        }]);
                    }}
                    className="underlined-link"
                >{cellProps.dataItem[field]}</a>
            </td>
        );
    };

    const ViewProfitCell = (props: GridCellProps) => {
        const field = props.field || "";
        return (
            <td colSpan={props.colSpan} style={props.style} className={props.className}>
                <div key={props.dataItem["estaff_order_number"]} style={{ textAlign: "right" }}>
                    {props.dataItem[field] != null &&
                        <Tooltip anchorElement="target" position="left">

                            {(props.dataItem["evaluated_profit"] === true) &&
                                <a href="#" title={i18n.t("estimate")} id="canton" className="fas fa-exclamation-circle orange-color" >&nbsp;</a>
                            }
                            <span style={{ opacity: props.dataItem["evaluated_profit"] === true ? 0.5 : 1 }}>
                                {(props.dataItem[field] != null) ? MoneyFormat(props.dataItem[field], props.dataItem["currency_code"]) : ""}
                            </span>
                        </Tooltip>
                    }

                </div>
            </td>
        );
    };

    const SupplierOrderNumCell = (owprops: GridCellProps) => {
        const field = owprops.field || "";
        const orders: [] = JSON.parse(owprops.dataItem[field]);
        return (
            <td colSpan={owprops.colSpan} style={owprops.style} className={owprops.className}>
                <span style={{ maxHeight: 23, overflow: "hidden" }}>
                    {(orders != null) && orders.map(function (e: any) {
                        return <>
                            <Button
                                className='supplier-button'
                                onClick={(el) => {
                                    props.onAddNewTabs(
                                        [
                                            {
                                                title: i18n.t('supplierOrderShort') + " # " + owprops.dataItem["estaff_order_number"]  + " " + owprops.dataItem["supplier_name"] ,
                                                id: SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX + owprops.dataItem["estaff_order_number"],
                                                content: {
                                                    name: SupplierOrderDetail,
                                                    props: { orderId: e.id }
                                                },
                                                visible: true,
                                                closable: true
                                            }
                                        ]
                                    )
                                }}
                                value={e.id} > {e.id}
                            </Button>&nbsp;
                        </>
                    })}
                </span>
                {(orders != null) && (orders.length > 3) &&
                    <span>...</span>
                }
            </td>
        );
    };

    React.useEffect(() => {
        let newDs = dataState;
        if (props.orderHeaderId && props.orderHeaderId > 0) {
            const initialFilter = {
                filter: {
                    logic: "and",
                    filters: [
                        {
                            field: "estaff_order_number",
                            operator: "eq",
                            value: '' + props.orderHeaderId + '',
                        },
                    ],
                }
            };

            newDs = Object.assign(dataState, initialFilter);
        }
        props.requestCustomerOrders(newDs, false, props.fetchEditableOrdersOnly);
    }, [dataState]);

    React.useEffect(() => {
        if (props.customerOrders.length > 0) {
            props.requestCustomerOrdersEditableStates(props.customerOrders);
        }
    }, [props.customerOrders]);


    React.useEffect(() => {
        if (props.exportedCustomerOrders.length > 0 && exportCustomerOrders == true) {
            setExportCustomerOrders(false);
            _export.current?.save(props.exportedCustomerOrders);
        }

        if (props.reloadCustomerOrders) {
            props.requestCustomerOrders(dataState, true, props.fetchEditableOrdersOnly);
        }

        if (props.updateOrderStatus) {
            props.onUpdateOrderStatus(props.selectedCustomerOrderIds, props.orderStatusId);
        }

        document.title = process.env.REACT_APP_SITE_TITLE + " " + i18n.t("customerOrders");
        updateGridFooterPager(props.total, i18n.t("orders"), "customer-order-grid")
    });

    React.useEffect(() => {
        props.fetchModuleParams();
        props.onfetchOrderStatuses();
        props.onfetchAllOrderStatuses();
        window.onclick = function (e: any) {
            var contextualMenuContainer = document.getElementById("orderStatusesContextuaLMenu");
            let targetEl = e;
            if (!contextualMenuContainer?.contains(targetEl.target)) {
                props.OnContextMenuClose();
            }
        };
    }, []);

    React.useEffect(() => {
        if (props.reloadCustomerOrders) {
            props.requestCustomerOrders(dataState, true, props.fetchEditableOrdersOnly);
        }
    }, [props.reloadCustomerOrders]);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    }

    const excelExport = () => {
        if (_export.current !== null) {
            props.requestExportCustomerOrders(accessToken, dataState);
            setExportCustomerOrders(true);
        }
    };

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            props.onSelectionChange(newSelectedState);
            setSelectedState(newSelectedState);
            if (props.onSelectOrdersCallback != undefined) {
                props.onSelectOrdersCallback(getSelectedOrderId(newSelectedState));
            }
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const isNoItemSelected = (): boolean => {
        var noItemIsSelected = true;
        var ordersToCancel: number[] = [];
        for (const k in selectedState) {
            if (selectedState[k]) {
                noItemIsSelected = false;
            }
        }

        return noItemIsSelected;
    }

    const getSelectedOrderId = (selectedState: SelectionState): number => {
        var orders: number[] = [];
        for (const k in selectedState) {
            if (selectedState[k]) {
                orders.push(Number(k));
            }
        }

        return orders[0];
    }

    const updateOrdersStatus = () => {
        props.onUpdateOrderStatus(props.selectedCustomerOrderIds, selectedorderStatusId);
    }

    const handleActionItemClick = (e: MenuSelectEvent) => {
        switch (e.item.text) {
            case i18n.t("exportSelectedToExcel"):
                if (props.selectedCustomerOrders != undefined && props.selectedCustomerOrders.length > 0) {
                    _export.current?.save(props.selectedCustomerOrders);
                }
                break;
            case i18n.t("exportAllToExcel"):
                excelExport()
                break;
            case i18n.t("editStatus"):
                if (getSelectedIds(selectedState).length > 0) {
                    props.onOpenUpdateOrderStatusWindow();
                }
                break;
            case i18n.t("merge"):
                break;
            case i18n.t("cancel"):
                props.OpenConfirmCancelOrderWindow(getSelectedIds(selectedState));
                break;
        }
    }

    const onToggleSelectAllRows = (e: CheckboxChangeEvent) => {
        let selectedState: any = {}
        if (!e.target.value) {
            setSelectedState({});
        } else {
            props.customerOrders.map(e => selectedState[e.estaff_order_number] = true)
        }

        setSelectedState(selectedState);
    }

    const StatusesFilterCell = (prop: any) => (
        <AutoCompleteFilterCell
            {...prop}
            data={props.allOrderStatuses}
            defaultItem={"Select category"}
        />
    );

    const DateFilterCells = (props: any) => (
        <DateFilterCell
            {...props}
            data={[]}
            defaultItem={"Select category"}
        />
    );


    return (

        <div>
            {props.isLoading && <>loading..</>}
            {props.confirmCreateOrderIntoCWIsVisible &&
                <ConfirmDialog
                    message={i18n.t("confirmCreateOrderIntoiCW")}
                    onPrompt={props.CloseConfirmCreateOrderIntoCW}
                    onConfirm={() => { props.onCreateIntoCW(props.orderToCreateIntoCW) }} />
            }
            {props.orderStatus != null && props.selectedCustomerOrderIds && props.confirmDialogIsVisible &&
                <ConfirmDialog
                    message={i18n.t("confirmUpdateOrdersStatus")}
                    onPrompt={props.CloseConfirmUpdateOrderStatusWindow}
                    onConfirm={() => { props.onUpdateOrderStatus(props.selectedCustomerOrderIds, props.orderStatus?.order_status_id) }} />
            }
            {props.selectedCustomerOrderIds && props.confirmCancelOrdersWindowIsVisible &&
                <ConfirmDialog
                    message={i18n.t("confirmCancelOrders")}
                    onPrompt={props.CloseConfirmCancelOrderWindow}
                    onConfirm={() => { props.onCacnelOrders(props.selectedCustomerOrderIds) }} />
            }
            {
                props.selectedCustomerOrderIds && props.updateOrderStatusWindowIsVisible &&
                <Dialog title={i18n.t("editStatus")} onClose={props.onCloseUpdateOrderStatusWindow}>
                    <DropDownList
                        style={{
                            width: "300px",
                        }}
                        textField="name"
                        dataItemKey="order_status_id"
                        data={props.orderStatuses}
                        onChange={props.onOrderStatusChanges}
                        required={true}
                    />
                    <DialogActionsBar>
                        <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={props.onCloseUpdateOrderStatusWindow} >{i18n.t("cancel")}</button>
                        <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={props.OpenConfirmUpdateOrderStatusWindow}
                        >{i18n.t("update")}</button>
                    </DialogActionsBar>
                </Dialog>

            }

            <Popup
                offset={props.contextualMenuOffset}
                show={props.contextualMenuIsOpen}
                popupClass={"popup-content"}
            >
                <div
                    id='orderStatusesContextuaLMenu'
                    tabIndex={-1}
                >
                    <Menu
                        className='contextual-menu'
                        vertical={true}
                        onSelect={props.onSelectContextualMenuOrderStatus}

                    >
                        {props.orderStatuses.map(os => <MenuItem data={os} text={os.name} />)}
                    </Menu>
                </div>
            </Popup>
            <LocalizationProvider language={i18n.language}>
                <IntlProvider locale={i18n.language}>
                    <ExcelExport ref={_export}>
                        <div className="customer-order-grid">
                            <Grid
                                sortable={true}
                                filterable={true}
                                pageable={{
                                    pageSizes: [10, 30, 50],
                                }}
                                resizable={true}
                                {...props.dataState}
                                data={props.customerOrders.map(item => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
                                onDataStateChange={dataStateChange}
                                total={props.total}
                                onHeaderSelectionChange={onHeaderSelectionChange}
                                dataItemKey={DATA_ITEM_KEY}
                                onSelectionChange={onSelectionChange}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: props.selectionMode,
                                }}
                            >
                                <GridToolbar>
                                    {props.displayAction && <div className='action-menu'>
                                        <div className='check-all-rows'> <Checkbox onChange={onToggleSelectAllRows}></Checkbox></div>
                                        <div className='action-menu-dropdown'>
                                            <Menu
                                                className='drop-down-menu'
                                                onSelect={handleActionItemClick}
                                                items={[{
                                                    "text": i18n.t("action") + " (" + getSelectedIds(selectedState).length + ")",
                                                    "items": [
                                                        { "text": i18n.t("exportSelectedToExcel") },
                                                        { "text": i18n.t("exportAllToExcel") },
                                                        { "text": i18n.t("editStatus") },
                                                        { "text": i18n.t("merge") },
                                                        { "text": i18n.t("cancel") },
                                                    ],
                                                }]} />
                                        </div>
                                    </div>}
                                    <><div className='grid-titles'>{i18n.t("customerOrders")}</div></>
                                </GridToolbar>
                                <Column
                                    filterable={false}
                                    headerCell={() => <td></td>}
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={
                                        props.customerOrders.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                    }
                                />
                                <Column field="order_date" filter="date" title={i18n.t('date') + "/" + i18n.t('hour')} cell={DateTimeCell} filterCell={DateFilterCells} width={160} />
                                <Column field="estaff_order_number" filter='numeric' title={i18n.t('#estaff')} cell={estaffLinkCell} width={117} filterCell={TextFilterCell} />
                                <Column field="order_status_name" filter='text' title={i18n.t('status')} headerCell={(e) => HeaderTooltipCell(e, i18n.t("updateStatusManual"))} cell={orderStatusCell} filterCell={StatusesFilterCell} width={190} />
                                <Column field="customer_name" filter='text' title={i18n.t('customer')} width={120} headerCell={(e) => HeaderTooltipCell(e, i18n.t("customerNameLong"))} filterCell={TextFilterCell} />
                                <Column field="order_po_number" filter='numeric' title={i18n.t('salesOrder#')} headerCell={(e) => HeaderTooltipCell(e, i18n.t("purchaseOrderNumberLong"))} width={180} filterCell={TextFilterCell} />
                                <Column field="sales_person_name" filter='text' title={i18n.t('vendor')} width={160} filterCell={TextFilterCell} />
                                <Column field="supplier_order_number" filter='numeric' title={i18n.t('purchaseOrder#')} cell={SupplierOrderNumCell} width={160} filterCell={TextFilterCell} />
                                <Column field="order_total" filter='numeric' title={i18n.t('total') + " (CAD)"} cell={MoneyCell} width={120} filterCell={TextFilterCell} />
                                <Column field="order_profit" filter='numeric' title={i18n.t('profit')} cell={ViewProfitCell} width={129} filterCell={TextFilterCell} />
                                <Column field="shipping_method_name" filter='text' title={i18n.t('shipping')} width={281} filterCell={TextFilterCell} />
                                <Column field="payment_method_name" filter='text' title={i18n.t('paymentMethod')} width={184} filterCell={TextFilterCell} />
                                <Column field="order_source_name" filter='text' title={i18n.t('source')} width={225} filterCell={TextFilterCell} />
                                <Column field="order_number_source" filter='text' title={i18n.t('external')} width={140} filterCell={TextFilterCell} />
                                <Column field="order_number_save" filter='numeric' title={i18n.t('transaction#')} width={160} filterCell={TextFilterCell} />
                                <Column field="invoice_number_list" title={i18n.t('#invoice')} cell={ViewInvoiceCell} width={120} filterable={false} />
                                {props.displayAction && <Column field="invoice_present" title={i18n.t('action')} cell={actionCell} locked={true} width={100} filterable={false} />}
                            </Grid>
                        </div>

                    </ExcelExport>
                </IntlProvider>
            </LocalizationProvider>

            {props.isLoading && loadingPanel}
        </div>
    );
}

export default connect(
    (state: ApplicationState, ownProps: MyComponentOwnProps) => ({
        ...state.customerOrders,
        ...state.Tabs,
        orderHeaderId: ownProps.orderHeaderId,
        selectionMode: ownProps.selectionMode,
        displayAction: ownProps.displayAction,
        fetchEditableOrdersOnly: ownProps.fetchEditableOrdersOnly
        //onSelectOrdersCallback: ownProps.onSelectOrdersCallback
    }), // Selects which state properties are merged into the component's props
    {...CustomerOrdersStore.actionCreators, ...TabsStore.actionCreators} // Selects which action creators are merged into the component's props
)(CustomerOrdersGrid as any); // eslint-disable-line @typescript-eslint/no-explicit-any