import * as CustomerOrders from './Orders/CustomerOrders';
import * as CustomerOrderItems from './Orders/CustomerOrderItems';
import * as SupplierOrders from './Orders/SupplierOrdersStore';
import * as SupplierOrderDetail from './Orders/SupplierOrderDetailStore';
import * as Notifications from './Notifications/Notifications';
import * as SearchProducts from './Product/SearchProducts';
import * as Invoice from './Invoice/Invoice';
import * as Tabs from './Tabs/Tabs';

// The top-level state object
export interface ApplicationState {
    customerOrders: CustomerOrders.CustomerOrdersState | undefined;
    supplierOrders: SupplierOrders.SupplierOrdersState | undefined;
    notifications: Notifications.NotificationState | undefined;
    searchProducts: SearchProducts.SearchProductsState | undefined;
    invoices : Invoice.InvoiceState | undefined;
    customerOrderItems : CustomerOrderItems.CustomerOrderItemsState | undefined;
    SupplierOrderDetail: SupplierOrderDetail.SupplierOrderDetailState | undefined;
    Tabs: Tabs.TabsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    customerOrders: CustomerOrders.reducer,
    supplierOrders: SupplierOrders.reducer,
    SupplierOrderDetail: SupplierOrderDetail.reducer,
    notifications: Notifications.reducer,
    searchProducts: SearchProducts.reducer,
    invoices : Invoice.reducer,
    customerOrderItems : CustomerOrderItems.reducer,
    Tabs: Tabs.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
