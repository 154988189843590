import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { Tab } from '../interfaces';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';



// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TabsState {
    tabs: Tab[];
    selected: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface OnAddNewTabsAction {
    type: 'ON_ADD_NEW_TABS';
    tabs: Tab[];
    selected: number;
}

interface OnRemoveTabAction {
    type: 'ON_REMOVE_TAB';
    tabs: Tab[];
}

interface onSelectTabAction {
    type: 'ON_SELECT_TAB';
    selected: number;
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = OnAddNewTabsAction | OnRemoveTabAction | onSelectTabAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    onAddNewTabs: (tabs: Tab[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let newTabs = getState().Tabs?.tabs;
        console.log("newTabs", newTabs);
        console.log("tabs", tabs);
        let selected = 0;
        tabs.map((t) => {
            let isExist = newTabs?.filter((tab) => {
                return tab.id == t.id;
            });

            if (isExist?.length == 0) {
                newTabs?.push(t);
            } else {

               selected = newTabs?.findIndex((tab) => {
                    return tab.id == t.id
                }) as number;

                console.log("index", selected);
            }
        });

        if (selected == 0 && newTabs !== undefined) {
            selected = newTabs?.length - 1;
        }

        dispatch({ type: 'ON_ADD_NEW_TABS', tabs: newTabs as Tab[], selected: selected });
    },
    onRemoveTab: (tabId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tabs = getState().Tabs?.tabs;
        let newTabs = tabs?.filter((t) => {
            return t.id != tabId;
        });

        dispatch({ type: 'ON_REMOVE_TAB', tabs: newTabs as Tab[] });
    },
    onselectTab: (e: TabStripSelectEventArguments): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let tabs = getState().Tabs?.tabs;
        if (tabs !== undefined) {
            let selected = 0;
            //setKey(Math.random());
            if (e.selected > tabs?.length - 1) {
                selected = 0
            } else {
                selected = e.selected;
            }

            dispatch({ type: 'ON_SELECT_TAB', selected: selected });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: TabsState = { tabs: [], selected: 0 };

export const reducer: Reducer<TabsState> = (state: TabsState | undefined, incomingAction: Action): TabsState => {
    if (state === undefined) {
        return unloadedState;
    }


    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ON_ADD_NEW_TABS':
            return {
                tabs: action.tabs,
                selected: action.selected
            }
        case 'ON_REMOVE_TAB':
            return {
                tabs: action.tabs,
                selected: action.tabs.length - 1
            }
        case 'ON_SELECT_TAB':
            return {
                tabs: state.tabs,
                selected: action.selected
            }
        default:
            return state;
    }
};
